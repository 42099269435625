import React, { useEffect, useState } from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { API_URL } from '../../api/api';
import { createFormResponse } from '../../services';
import { Button, Col, Input, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { motion } from 'framer-motion';
import { mobilVariant } from '../../components/animation';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPageByHeaders } from '../../store/slices/pageByHeaderSlice';
import { fetchSettingById } from '../../store/slices/settingSlice';
import { fetchFormByIds } from '../../store/slices/formByIdSlice';
import { urlById } from '../../utility/general';
import phone from "../../images/phone.png";
import mail from '../../images/mail.png'
import map from '../../images/map.png'
import { FaLocationDot } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";




const ContactPage = () => {
    const headerID = localStorage.getItem("headerID");
    const dispatch = useDispatch();
    const data = useSelector((state) => state.pageByHeader.page);
    const setting = useSelector((state) => state.setting.settings);
    const forms = useSelector((state) => state.formById.form);
    const [lang, setLang] = useState(localStorage.getItem("lang") || "EN");
    const [load, setLoad] = useState(false);
    const [modal, setModal] = useState(false);
    const [success, setSuccess] = useState(false);
    const [accept, setAccept] = useState(false);
    const [displayText, setDisplayText] = useState('');
    const [formData, setFormData] = useState({});
    const [isSuccess, setIsSuccess] = useState(false)

    const formTypes = (id) => {
        if (id === 1) {
            return "input";
        } else if (id === 2) {
            return "textarea";
        } else {
            return "input";
        }
    };

    const getData = async () => {
        try {
            if (data && data.imageText) {
                const parsedData = lang === "TR" ? data.imageText : data.imageTextEN;
                setDisplayText(parsedData);
            }
        } catch (error) {
            console.error('Error parsing JSON:', error);
            setDisplayText('Default Text');
        } finally {
            if (setting !== null && setting !== undefined) {
                setTimeout(() => {
                    setLoad(true);
                }, 300);
            }
        }
    };

    const postForm = async () => {
        try {
            if (JSON.stringify(formData) !== "{}") {
                const response = await createFormResponse(JSON.stringify(formData), 2);
                if (response.message === 'Success') {
                    setSuccess(true);
                } else {
                    setSuccess(false);
                }
                setIsSuccess(true)
            } else {
                setIsSuccess(false)
                setModal(true)
            }
        } catch (error) {
            console.error('Error posting form:', error);
            setSuccess(false);
        } finally {
            setModal(true);
            setTimeout(() => {
                setModal(false);
            }, 3000);
        }
    };

    useEffect(() => {
        dispatch(fetchPageByHeaders(headerID || urlById(window.location.pathname.replace("/content/", ""))));
        dispatch(fetchSettingById());
        dispatch(fetchFormByIds());
    }, [dispatch]);

    useEffect(() => {
        if (data) {
            getData();
        }
    }, [data, lang]);

    return (
        <motion.div {...mobilVariant}>
            {data && data.seo ? (
                <Helmet>
                    <title>{data.seo.seoTitle}</title>
                    <meta name="description" content={data.seo.seoDescription} />
                    <meta name="keywords" content={data.seo.seoKeywords} />
                    <meta name="author" content={data.seo.seoAuthor} />
                </Helmet>
            ) : null}
            <div className='contact'>
                <Header />
                <div className='top w-100'>
                    {data && data.fileFullPath && typeof data.fileFullPath === 'string' && data.fileFullPath.trim() !== '' && (
                        <img
                            src={`${API_URL}${data.fileFullPath}`}
                            alt='Descriptive image description'
                            onError={(e) => e.currentTarget.src = 'fallback-image-url'}
                        />
                    )}
                    <div className='info w-100'>
                        <Row className='w-75'>
                            <Col md={7} className='mb-3 d-flex align-items-center text-white' style={{ marginTop: 52}}>
                                {setting && (
                                    <div>
                                        <div className='mb-5'>
                                            <div>
                                                <h3>{lang === "TR" ? setting.address1Title : setting.address1TitleEN}</h3>
                                            </div>
                                            <div className='mb-3 mt-3 d-flex justify-content-start'>
                                                <div><FaLocationDot /></div>
                                                <div className='ml-3'>
                                                    <a href={setting.location1}><span dangerouslySetInnerHTML={{
                                                        __html: lang === "TR"
                                                            ? setting.address1.replace(/\n/g, '<br/>')
                                                            : setting.address1EN.replace(/\n/g, '<br/>')
                                                    }} /></a>
                                                </div>
                                            </div>
                                            <div className='mb-3 d-flex justify-content-start'>
                                                <div><FaPhone /></div>
                                                <div className='ml-3'><a href={`tel:${setting.phone1 || ''}`}>{setting.phone1 || 'Default Phone'}</a></div>
                                            </div>
                                            {setting.mail1 === null ? null : <div className='mb-3 d-flex justify-content-start'>
                                                <div><IoMdMail /></div>
                                                <div className='ml-3'><a href={`mailto:${setting.mail1 || ''}`}>{setting.mail1}</a></div>
                                            </div>}
                                        </div>

                                        {
                                            setting === null ? null :
                                                setting.address2Title === "" ? null : <div>
                                                    <div className='mt-3'>
                                                        <h3>{lang === "TR" ? setting.address2Title : setting.address2TitleEN}</h3>
                                                    </div>
                                                    <div className='mb-3 d-flex justify-content-start'>
                                                        <div><FaLocationDot /></div>
                                                        <div className='ml-3'>
                                                            <a href={setting.location2}><span dangerouslySetInnerHTML={{
                                                                __html: lang === "TR"
                                                                    ? setting.address2.replace(/\n/g, '<br/>')
                                                                    : setting.address2EN.replace(/\n/g, '<br/>')
                                                            }} /></a>
                                                        </div>
                                                    </div>
                                                    <div className='mb-3 d-flex justify-content-start'>
                                                        <div><FaPhone /></div>
                                                        <div className='ml-3'><a href={`tel:${setting.phone2 || ''}`}>{setting.phone2}</a></div>
                                                    </div>
                                                    <div className='mb-3 d-flex justify-content-start'>
                                                        <div><IoMdMail /></div>
                                                        <div className='ml-3'><a href={`mailto:${setting.mail2 || ''}`}>{setting.mail2}</a></div>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                )}
                            </Col>
                            <Col md={5} sm={12}>
                                <div className='w-100 form'>
                                    {forms?.formElements?.length > 0 ? forms.formElements.map((item, index) => (
                                        <div key={index} className='formelement'>
                                            <div className='mr-3 d-flex align-items-center'>
                                                <span className='text-white'>
                                                    {lang === "TR" ? item.title : item.titleEN}
                                                </span>
                                            </div>
                                            <div>
                                                <Input
                                                    type={formTypes(item.formElementType)}
                                                    onChange={(e) => setFormData(prevFormData => ({
                                                        ...prevFormData,
                                                        [item.formElementID]: e.target.value
                                                    }))}
                                                />
                                            </div>
                                        </div>
                                    )) : null}
                                    <div className='d-flex justify-content-end'>
                                        <div className='text-white metin'>
                                            <span className='kvkk'>{lang === "TR" ? "Kişisel verileriniz Aydınlatma Metni kapsamında işlenmektedir. Kişisel Verilerin Korunması ve İşlenmesi Politikası'nı okuduğunuzu ve kabul ettiğinizi onaylayınız." : "Your personal data is processed within the scope of the Information Text. Confirm that you have read and accepted the Personal Data Protection and Processing Policy."}</span>
                                        </div>
                                    </div>
                                    <div className='enter'>
                                        <label className='checkbox-label'>
                                            <Input type='checkbox' onChange={(e) => setAccept(e.target.checked)} />
                                            <span>{lang === "TR" ? "Kişisel verilerimin işlenmesini kabul ediyorum" : "I accept the processing of my personal data"}</span>
                                        </label>
                                    </div>
                                    <div className='d-flex justify-content-end mt-3'>
                                        <Button color="primary" onClick={() => postForm()} disabled={!accept}>
                                            {lang === "TR" ? "Gönder" : "Send"}
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <Footer />
                <Modal isOpen={modal} toggle={() => setModal(!modal)}>
                    <ModalHeader toggle={() => setModal(!modal)}>{success ? lang === "TR" ? 'Başarıyla Gönderildi' : "Successfully Sent" : lang === "TR" ? 'Gönderim Başarısız' : "Sending Failed"}</ModalHeader>
                    <ModalBody>
                        {isSuccess ? success ? lang === "TR" ? 'Formunuz başarıyla gönderilmiştir.' : "Your form has been sent successfully." : lang === "TR" ? 'Form gönderimi sırasında bir hata oluştu.' : "An error occurred while submitting the form" : null}
                        {!isSuccess ? lang === "TR" ? 'Lütfen gerekli alanları doldurunuz!' : "Please fill in the required fields!" : null}
                    </ModalBody>
                </Modal>
            </div>
        </motion.div>
    );
};

export default ContactPage;
